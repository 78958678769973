<template>
  <div class="cropper-img-container">
    <div class="trigger-wrapper" @click="selectFile">
      <slot name="trigger">
        <div v-if="!isFail" class="upload-img">
          <el-icon>
            <svg
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              data-v-78e17ca8=""
            >
              <path
                fill="currentColor"
                d="M896 256H128v576h768V256zm-199.424-64-32.064-64h-304.96l-32
                64h369.024zM96 192h160l46.336-92.608A64 64 0 0 1 359.552
                64h304.96a64 64 0 0 1 57.216 35.328L768.192 192H928a32 32 0
                0 1 32 32v640a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V224a32 32
                0 0 1 32-32zm416 512a160 160 0 1 0 0-320 160 160 0 0 0 0
                320zm0 64a224 224 0 1 1 0-448 224 224 0 0 1 0 448z"
              />
            </svg>
          </el-icon>
          <span>上传头像</span>
        </div>
        <div v-else class="upload-fail">
          <div class="fail-text">
            上传失败
          </div>
          <div class="re-upload">
            重新上传
          </div>
        </div>
      </slot>
      <input
        ref="imgFile"
        type="file"
        class="none"
        :disabled="disabled"
        :accept="imgSuffix?.join(', ')"
        @change="openDialog"
      >
    </div>

    <QsDialog
      v-model:visible="dialogVisible"
      width="800px"
      :title="`上传${dialogTitle || title}`"
      :handle-confirm="uploadImg"
      append-to-body
      :custom-class="dialogCustomClass"
      :type="type"
      @open="initCropper"
      @closed="destroyCropper"
    >
      <div class="cropper-dialog">
        <div class="cropper-dialog-left">
          <div class="f16-bold mb16">
            {{ title || dialogTitle }}预览
          </div>
          <div ref="preview" class="cropper-preview" />
          <div class="f16-bold mt16 mb8">
            上传要求
          </div>
          <div class="f14-light" v-html="tip" />
        </div>
        <div class="cropper-dialog-right">
          <div class="f16-bold mb16">
            选取
          </div>
          <div ref="cropperSelector" class="cropper-selector">
            <img
              ref="img"
              class="cropper-img"
              src=""
              alt=""
            >
          </div>
        </div>
      </div>
    </QsDialog>
  </div>
</template>

<script>
import QsDialog from '@qs-plus/components/QsDialog.vue'
import { useQiniu } from '@qs-plus/compositions/useQiniu'
import { MB_IN_BYTE, getSize } from '@shared/util/byte'
import { pressImg } from '@shared/util/img'
import { getPromise } from '@shared/util/promise'
import { addCropperBoxWheel } from '@shared/util/cropper'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

const IMG_SUFFIX = ['.png', '.jpeg', '.jpg']
export default {
  components: { QsDialog },
  props: {
    title: {
      type: String,
      default: '封面',
    },
    dialogTitle: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '只支持 .png、.jpeg、.jpg 格式，大小不超过2M',
    },
    minSize: {
      type: Number,
      default: 0,
    },
    sizeLimit: {
      type: Number,
      default: 2 * MB_IN_BYTE,
    },
    aspectRatio: {
      type: [Number, NaN],
      default: 16 / 9,
    },
    autoCropArea: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    handleConfirm: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      default: url => Promise.resolve(),
    },
    getUploadToken: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      default: file => Promise.resolve(),
    },
    customUpload: {
      type: [Function, undefined, null],
      default: undefined,
    },
    needCropper: {
      type: Boolean,
      default: true,
    },
    needCompression: {
      type: Boolean,
      default: true,
    },
    dialogCustomClass: {
      type: String,
      default: '',
    },
    minCropBoxWidth: {
      type: Number,
      default: 50,
    },
    type: {
      type: String,
      default: '',
    },
    wheelZoomRatio: {
      type: Number,
      default: 0.1,
    },
    selectFileFn: {
      type: [Function, null],
      default: null,
    },
    resizable: {
      type: Boolean,
      default: true,
    },
    imgSuffix: {
      type: Array,
      default: () => IMG_SUFFIX,
    },
  },
  emits: ['uploaded', 'open', 'close', 'uploadFile'],
  setup(props) {
    return {
      ...useQiniu({ getUploadToken: props.getUploadToken }),
    }
  },
  data() {
    return {
      dialogVisible: false,
      localImg: null,
      cropper: null,
      uploadObservable: null,
      isFail: false,
      fileName: '',
    }
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    },
  },
  methods: {
    selectFile() {
      if (this.selectFileFn) {
        this.selectFileFn()
        return
      }
      this.$refs.imgFile.value = ''
      this.$refs.imgFile.click()
    },
    // eslint-disable-next-line complexity
    openDialog(e) {
      this.fileName = ''
      if (!e.target) {
        // 直接使用图片 url
        if (!this.imgSuffix.includes(`.${e.url.split('?')[0].split('.').pop()}`)) {
          this.$info(`请使用 ${this.imgSuffix.join('、')} 格式的图片`)
          return false
        }
        if (this.minSize > e.size) {
          this.$info(`图片不能小于${getSize(this.minSize)}`)
          return false
        }
        if (this.sizeLimit < e.size) {
          this.$info(`图片不能超过${getSize(this.sizeLimit)}`)
          return false
        }
        if (!this.needCropper) {
          this.$emit('uploaded', e.url)
          this.$emit('uploadFile', {
            name: e?.name || '',
            url: e.url,
          })
          return true
        }
        const img = new Image()
        img.crossOrigin = 'anonymous'
        img.src = e.url
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this
        img.onload = function () {
          canvas.width = img.naturalWidth
          canvas.height = img.naturalHeight
          ctx.drawImage(this, 0, 0, canvas.width, canvas.height)
          that.localImg = canvas.toDataURL('image/png', 1)
          that.dialogVisible = true
        }
        img.onerror = () => this.$info('打开图片失败')
        return true
      }
      if (e.target.files.length === 0) {
        return false
      }
      if (!this.imgSuffix.includes(`.${e.target.files[0].name.split('.').pop()}`)) {
        this.$info(`请上传 ${this.imgSuffix.join('、')} 格式的图片`)
        return false
      }
      if (this.minSize > e.target.files[0].size) {
        this.$info(`图片不能小于${getSize(this.minSize)}`)
        return false
      }
      if (this.sizeLimit < e.target.files[0].size) {
        this.$info(`图片不能超过${getSize(this.sizeLimit)}`)
        return false
      }
      this.localImg = URL.createObjectURL(e.target.files[0])
      this.fileName = e.target.files[0]?.name
      if (!this.needCropper) {
        this.doUpload(e.target.files[0])
        return true
      }
      this.dialogVisible = true
      return true
    },

    initCropper() {
      if (this.$refs.img) {
        this.doInitCropper()
      } else {
        Promise.resolve().then(() => this.doInitCropper())
      }
    },
    doInitCropper() {
      this.$refs.img.src = this.localImg
      this.cropper = new Cropper(this.$refs.img, {
        preview: this.$refs.preview,
        // see https://github.com/fengyuanchen/cropperjs/blob/main/README.md
        dragMode: 'move',
        aspectRatio: this.aspectRatio,
        autoCropArea: this.autoCropArea,
        viewMode: 1,
        checkCrossOrigin: false,
        background: false,
        minCropBoxWidth: this.minCropBoxWidth,
        wheelZoomRatio: this.wheelZoomRatio,
        ready: () => {
          addCropperBoxWheel(
            this.cropper,
            {
              imgRef: this.$refs.img,
              cropperSelectorRef: this.$refs.cropperSelector,
            },
            this.wheelZoomRatio,
          )
        },
      })
    },
    destroyCropper() {
      this.$refs.img.src = ''
      this.cropper && this.cropper.destroy()
      this.cropper = null
    },

    uploadImg() {
      const { promise, resolve } = getPromise()
      this.cropper.getCroppedCanvas({ }).toBlob((file) => {
        file.name = 'img.png'
        this.doUpload(file).then(() => resolve())
      }, 'image/png', 0.92)
      return promise
    },

    doUpload(file) {
      if (this.customUpload) {
        return this.customUpload(file)
          .then((url) => {
            this.handleConfirm(url)
            this.dialogVisible = false
            this.isFail = false
            this.$emit('uploaded', url)
            this.$emit('uploadFile', {
              name: this.fileName,
              url,
            })
          })
          .catch(() => {
            this.isFail = true
            this.$error('上传失败')
          })
      }
      const { promise, resolve } = getPromise()
      ;(this.needCompression ? pressImg(file) : Promise.resolve(file)).then(
        (file) => {
          this.uploadFile(file, {
            error: () => {
              resolve()
              this.isFail = true
              this.$error('上传失败')
            },
            complete: () => {
              this.handleConfirm(this.uploadData.downloadUrl).finally(() =>
                resolve(),
              )
              this.dialogVisible = false
              this.isFail = false
              this.$emit('uploaded', this.uploadData.downloadUrl)
              this.$emit('uploadFile', {
                name: this.fileName,
                url: this.uploadData.downloadUrl,
              })
            },
          }).then(success => !success && resolve())
        },
      )
      return promise
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@shared/style/color';
.cropper-img-container {
  display: inline-block;

  .trigger-wrapper {
    display: inline-flex;
    align-items: center;
    input.none {
      display: none;
    }
  }

  .upload-img,
  .upload-fail {
    display: flex;
    border: 1px dashed $dark-light;
    border-radius: 4px;
    width: 10em;
    height: 10em;
    color: $dark-light;
    background: rgb(237 242 247 / 30%);
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i {
      font-size: 24px;
    }
  }
  .upload-fail {
    .fail-text {
      color: $red;
    }
    .re-upload {
      display: inline-block;
      border: 1px solid $border-bg;
      border-radius: 16px;
      padding: 0.6em 1.2em;
      font-size: 0.88em;
      line-height: 1;
    }
  }
}
.cropper-dialog {
  display: flex;

  &-left {
    width: 238px;
    .cropper-preview {
      overflow: hidden;
      border-radius: 4px;
      width: 200px;
      height: 200px;
      background: $border-bg;
    }
  }
  &-right {
    width: calc(100% - 238px);

    .cropper-selector {
      width: 100%;
      height: 357px;
      background-color: $border-bg;
    }
  }
}
</style>

<style lang="scss">
@import '@shared/style/cropper';
</style>
